import { FC, ReactElement } from 'react';

import MobileSocialNavSection from './sections/MobileSocialNav';
import SocialNavSection from './sections/SocialNavSection';
import AboutUsSection from './sections/AboutUsSection';
import Services from './sections/Services';
import CTASection from './sections/CTASection';
import MapSection from './sections/MapSection';
import Footer from './sections/Footer';
import Landing from './sections/Landing';

import logoRed from '../assets/logo-green.svg';
import nhsLogo from '../assets/nhs.svg';
import useScreenSize from '../hooks/useScreenSize';
import Partners from './sections/Partners';

const Home: FC = (): ReactElement => {
  const { width } = useScreenSize();
  return (
    <div className="lg:max-w-[1440px] max-w-full mx-auto">
      {width >= 968 && <SocialNavSection />}
      <section>
        <nav className="px-4 py-4 sm:px-8 flex flex-row justify-between">
          <div className="flex flex-row justify-center self-center">
            <div className="flex flex-row justify-center">
              <img src={logoRed} width={65} height={65} />
              <h1 className="break-words text-sm sm:text-lg md:text-4xl/tight font-semibold text-primary my-auto self-center">
                First Care Physiotherapy & Sports Clinic
              </h1>
            </div>
          </div>
          {width >= 960 ? null : (
            <div className="lg:invisible md:invisible sm:visible xs:visible self-center">
              <a
                href="https://clientportal.uk.powerdiary.com/clientportal/fcpsc"
                className="whitespace-nowrap text-white no-underline bg-primary rounded-lg hover:bg-secondary hover:underline hover:text-blue-200 text-xs sm:text-sm md:text-base p-4"
              >
                Book Today
              </a>
            </div>
          )}
        </nav>
      </section>
      {width < 968 && <MobileSocialNavSection />}
      <Landing />
      <Services />
      <AboutUsSection />
      <Partners />
      <CTASection />
      <MapSection />
      <div className="sticky bottom-0 bg-[#003087] text-white max-w-screen-xl px-4 py-4 sm:px-6 lg:flex lg:items-center lg:px-8">
        <div className="mx-auto">
          <p className="max-w-fit block mx-auto text-center text-balance">
            As advertised within King George Hosptial{' '}
            <img src={nhsLogo} alt="" className="inline h-4" /> please{' '}
            <a
              href="https://clientportal.uk.powerdiary.com/clientportal/fcpsc"
              className="inline whitespace-nowrap text-white no-underline bg-primary rounded-lg hover:bg-secondary hover:underline hover:text-blue-200 text-xs sm:text-sm md:text-base p-2"
            >
              Book Today
            </a>{' '}
            to find out more.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
